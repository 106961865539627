import React from "react";

function Footer() {
  return (
    <footer>
      <p> جميع الحقوق محفوظة,الغانمي. 2024 ©</p>
    </footer>
  );
}

export default Footer;
