export const Hotels = [
  {
    id: "1",
    english_name: "waw-jada",
    name: {
      ar: "فندق واو جدة الستين",
      en: "Jeddah Wow Hotel (Al Setten)",
    },
    logo: "/assets/images/waw-jada-logo.png",
    home_image: "assets/images/waw-jada.jpg",
    home_description: {
      ar: " فندق واو جدة (الستين) هو فندق يقع في حي شارع فلسطين في جدة . يتميز بموقعه الجيد حيث يبعد 3  ميل عن مركز التسوق الأندلس، 4  ميل عن كورنيش جدة و4  ميل عن متحف بيت ناصيف . يوفر الفندق خدمة الغرف ومكتب استقبال يعمل على مدار 24 ساعة ",
      en: "Wow Jeddah Hotel (Al Setten) is a hotel located in the Palestine Street district in Jeddah. It is well-located, being 3 miles from the Andalus Shopping Center, 4 miles from the Jeddah Corniche, and 4 miles from the Beit Nassif Museum",
    },
    full_description: {
      ar: " فندق واو جدة (الستين) هو فندق يقع في حي شارع فلسطين في جدة  . يوفر الفندق خدمة الغرف ومكتب استقبال يعمل على مدار 24 ساعة، بالإضافة إلى خدمة الواي فاي المجانية في جميع أنحاء الممتكن . الفندق يحتوي على غرف عائلية . يبعد مركز التسوق جدة مول 4  ميل عن الفندق، بينما يبعد نافورة الملك فهد 7  ميل . أقرب مطار هو مطار الملك عبد العزيز الدولي ويبعد 12 ميل عن فندق واو جدة (الستين) .الفندق يقدم خدمات متعددة للضيوف، بما في ذلك خدمة الغرف ومكتب استقبال يعمل على مدار 24 ساعة . يتميز الفندق بتوفير خدمة الواي فاي المجانية في جميع أنحاء المكان . الفندق يحتوي على غرف عائلية ، مما يجعله مكانًا مثاليًا للعائلات الكبيرة أو الصغيرة.بالإضافة إلى ذلك، يقع الفندق بالقرب من العديد من المعالم السياحية الرئيسية في جدة. يبعد مركز التسوق جدة مول 4.9 ميل عن الفندق ، مما يجعله مكانًا مثاليًا للذين يرغبون في التسوق. بينما يبعد نافورة الملك فهد 7.1 ميل ، وهي واحدة من أبرز المعالم السياحية في المدينة.أقرب مطار هو مطار الملك عبد العزيز الدولي ويبعد 12 ميل عن الفندق ، مما يجعل الوصول إلى الفندق والمغادرة منه سهلاً للغاية. يتمتع الفندق بتقييمات ممتازة من الضيوف السابقين ، مما يجعله خيارًا رائعًا للإقامة في جدة.",
      en: "Wow Jeddah Hotel (Al Setten) is a hotel located in the Palestine Street district in Jeddah. The hotel offers room service and a 24-hour reception desk, as well as free Wi-Fi throughout the property. The hotel has family rooms. Jeddah Mall shopping center is 4 miles from the hotel, while King Fahd Fountain is 7 miles away. The nearest airport is King Abdulaziz International Airport, which is 12 miles from Wow Jeddah Hotel (The Sixty).The hotel offers multiple services to guests, including room service and a 24-hour reception desk. The hotel is characterized by providing free Wi-Fi service throughout the place. The hotel has family rooms, making it an ideal place for large or small families.In addition, the hotel is located near many major tourist attractions in Jeddah. Jeddah Mall shopping center is 4.9 miles from the hotel, making it an ideal place for those who want to shop. While King Fahd Fountain is 7.1 miles away, it is one of the most prominent tourist landmarks in the city.The nearest airport is King Abdulaziz International Airport, which is 12 miles from the hotel, making it very easy to get to and from the hotel. The hotel enjoys excellent ratings from previous guests, making it a great choice for a stay in Jeddah.",
    },
    services: {
      ar: [
        "مطعم",
        "ساونا",
        "غرفة بخار",
        "ميني جيم",
        "خدمة حمل الأمتعة",
        "قنوات فضائية",
        "انترنت عالي السرعة",
        "مغسلة مركزية",

        "كوفي شوب على مدار الساعة",
        "حمام سباحة اطفال",
        "منطقة العاب اطفال",
        "خدمة نظافة الغرف",
        "خدمة غرف شهر العسل",

        "خدمة التوصيل من والى المطار",
        "خدمة طباعة المتستندات",
        "مكتب امن",
        "مكتب استقبال على مدار الساعة",
        "خزائن لحفظ الودائع الشخصية",
      ],
      en: [
        "Restaurant",
        "Sauna",
        "Steam room",
        "Mini gym",
        "Luggage service",
        "Satellite channels",
        "High-speed internet",
        "Central laundry",

        "24-hour coffee shop",
        "Children's swimming pool",
        "Children's play area",
        "Room cleaning service",
        "Honeymoon room service",

        "Airport pick-up and drop-off service",
        "Document printing service",
        "Security office",
        "24-hour reception desk",
        "Lockers for personal deposits",
      ],
    },

    email: "wowhotel60@alghanmigroup.com",
    instagram:
      "https://www.instagram.com/wow_jeddah_hotel/?igshid=MzMyNGUyNmU2YQ%3D%3D",
    facebook: "",
    x: "https://x.com/wow_jeddah?t=_Xhf9jzB88ZqIrgG9PhGQg&s=09",
    phone: "+966 53 511 5151",
    address: {
      en: "Al Sittin Road, Al Abbas Ibn Abdul Muttalib, Jeddah 23218, Saudi Arabia",
      ar: "طريق الستين، العباس بن عبدالمطلب، جدة 23218، المملكة العربية السعودية",
    },
    map_src:
      "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3711.4244041381094!2d39.18878417445017!3d21.530254380247523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1701417579237!5m2!1sen!2sus",
    images: [
      "/assets/images/waw-jada/1.jpg",
      "/assets/images/waw-jada/2.jpg",
      "/assets/images/waw-jada/3.jpg",
      "/assets/images/waw-jada/4.jpg",
      "/assets/images/waw-jada/5.jpg",
      "/assets/images/waw-jada/6.jpg",
      "/assets/images/waw-jada/7.jpg",
      "/assets/images/waw-jada/8.jpg",
      "/assets/images/waw-jada/9.jpg",
      "/assets/images/waw-jada/10.jpg",
      "/assets/images/waw-jada/11.jpg",
      "/assets/images/waw-jada/12.jpg",
      "/assets/images/waw-jada/13.jpg",
      "/assets/images/waw-jada/14.jpg",
      "/assets/images/waw-jada/15.jpg",
      "/assets/images/waw-jada/16.jpg",
      "/assets/images/waw-jada/17.jpg",
      "/assets/images/waw-jada/18.jpg",
      "/assets/images/waw-jada/19.jpg",
      "/assets/images/waw-jada/20.jpg",
      "/assets/images/waw-jada/21.jpg",
      "/assets/images/waw-jada/22.jpg",
      "/assets/images/waw-jada/23.jpg",
    ],

    booking: "https://www.booking.com/hotel/sa/fndq-ww-jd-lstyn.ar.html",
  },
  {
    id: "2",
    english_name: "obhur",
    name: {
      ar: "نزل أبحر للأجنحة الفندقية",
      en: "Onhur Home",
    },
    logo: "/assets/images/obhur-logo.png",
    home_image: "assets/images/obhur-hotel.png",
    home_description: {
      ar: " نزل أبحر للأجنحة الفندقية في جدة هو مكان إقامة  يتميز بإطلالاته على المدينة وتوفر خدمة الواي فاي ومواقف السيارات مجاناً . كل وحدة في النزل مخدمة ومكيفة وتحتوي على حمام خاص و بعض الوحدات تحتوي أيضاً على مطبخ مجهز بميكروويف وموقد مسطح ",
      en: "Obhur Home for Hotel Suites in Jeddah is an accommodation that features city views and offers free Wi-Fi and parking. Each unit in the hotel is serviced and air-conditioned, and contains a private bathroom",
    },
    full_description: {
      ar: " نزل أبحر للأجنحة الفندقية في جدة هو مكان إقامة يقع على بُعد 8,8 كم من مسجد الرحمة . يتميز بإطلالاته على المدينة وتوفر خدمة الواي فاي ومواقف السيارات مجاناً . كل وحدة في النزل مكيفة وتحتوي على حمام خاص وبيديت وتلفزيون بشاشة مسطحة وثلاجة . بعض الوحدات تحتوي أيضاً على مطبخ مجهز بميكروويف وموقد مسطح .يقع النزل على بُعد 12 كم من رد سي مول و13 كم من مول العرب . المطار الأقرب هو مطار الملك عبد العزيز الدولي، ويبعد مسافة 11 كم . يتمتع النزل بتقييمات جيدة جداً من الضيوف السابقين ، مما يجعله خيارًا رائعًا للإقامة في جدة. ",
      en: "Obhur Home for Hotel Suites in Jeddah is an accommodation located 8.8 km from Al Rahma Mosque. It features city views and offers free Wi-Fi and parking. Each unit in the hotel is air-conditioned and contains a private bathroom, bidet, flat-screen TV, and refrigerator. Some units also have a kitchen equipped with a microwave and a flat stove. The hotel is located 12 km from Red Sea Mall and 13 km from Arab Mall. The nearest airport is King Abdulaziz International Airport, which is 11 km from the hotel. The hotel enjoys very good ratings from previous guests, making it a great choice for a stay in Jeddah.",
    },

    services: {
      ar: [
        "كوفي شوب على مدار الساعة",
        "خدمة نظافة الغرف",
        "خدمة حمل الامتعة",
        "قنوات فضائية",
        "انترنت عالي السرعة",
        "مرافق الكي و الغسل",
        "خدمة غرف شهر العسل",
        "مكتب امن",
        "خدمة التوصيل من والى المطار",
        "خدمة طباعة المتستندات",
        "مكتب استقبال على مدار الساعة",
        "خزائن لحفظ الودائع الشخصية",
      ],
      en: [
        "24-hour coffee shop",
        "Room cleaning service",
        "Luggage service",
        "Satellite channels",
        "High-speed internet",
        "Ironing and washing facilities",
        "Honeymoon room service",
        "Security office",
        "Airport pick-up and drop-off service",
        "Document printing service",
        "24-hour reception desk",
        "Lockers for personal deposits",
      ],
    },

    email: "obhorhome@alghanmigroup.com",
    instagram:
      "https://instagram.com/obhor_home?igshid=MzMyNGUyNmU2YQ==&utm_medium=social&utm_source=heylink.me",
    facebook:
      "https://www.facebook.com/profile.php?id=61550851036261&utm_medium=social&utm_source=heylink.me",
    x: "https://x.com/obhur_home?s=21&utm_medium=social&utm_source=heylink.me",
    booking: "https://www.booking.com/Share-icfOvP",
    phone: "+966 56 622 7000",
    address: {
      en: "7597 King Abdulaziz Branch Rd, Al Basateen, Jeddah 23719, Saudi Arabia",
      ar: "7597 طريق الملك عبد العزيز فرع، البساتين، جدة 23719، المملكة العربية السعودية",
    },
    map_src:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3706.936254242263!2d39.10242507527555!3d21.70519998011883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDQyJzE4LjciTiAzOcKwMDYnMTguMCJF!5e0!3m2!1sen!2s!4v1701158615929!5m2!1sen!2s",
    images: [
      "/assets/images/obhur/1.jpg",
      "/assets/images/obhur/2.jpg",
      "/assets/images/obhur/3.jpg",
      "/assets/images/obhur/4.jpg",
      "/assets/images/obhur/5.jpg",
      "/assets/images/obhur/6.jpg",
      "/assets/images/obhur/7.jpg",
      "/assets/images/obhur/8.jpg",
      "/assets/images/obhur/9.jpg",
      "/assets/images/obhur/10.jpg",
      "/assets/images/obhur/11.jpg",
      "/assets/images/obhur/12.jpg",
      "/assets/images/obhur/13.jpg",
      "/assets/images/obhur/14.jpg",
      "/assets/images/obhur/15.jpg",
      "/assets/images/obhur/16.jpg",
      "/assets/images/obhur/17.jpg",
      "/assets/images/obhur/18.jpg",
      "/assets/images/obhur/19.jpg",
    ],

    booking: "https://www.booking.com/hotel/sa/nzl-bhr-obhur-home.ar.html",
  },
  {
    id: "3",
    english_name: "carwan",
    name: { ar: "فندق كروان جدة", en: "Carwan Hotel" },
    logo: "/assets/images/carwan-logo.png",
    home_image: "assets/images/carwan-hotel.png",
    home_description: {
      ar: " فندق كروان جدة هو فندق يقع في شارع محمد زيدان، حي النعيم في جدة . يقع الفندق على بُعد 2,9 كم من مجمع العرب ويضم أماكن إقامة مع مطعم ومواقف خاصة مجانية للسيارات ومسبح خارجي ومركز للياقة البدنية   ",
      en: "Carwan Hotel Jeddah is a hotel located on Mohammed Zaidan Street, Al Naeem district in Jeddah. The hotel is 2,9 km from Arab Mall and offers accommodation with a restaurant, free private parking, an outdoor swimming pool, and a fitness center",
    },
    full_description: {
      ar: " فندق كروان جدة هو فندق يقع في شارع محمد زيدان، حي النعيم في جدة . يقع الفندق على بُعد 2.9 كم من مجمع العرب ويضم أماكن إقامة مع مطعم ومواقف خاصة مجانية للسيارات ومسبح خارجي ومركز للياقة البدنية . تشمل المرافق المتوفرة في مكان الإقامة خدمة الغرف وصالة مشتركة، بالإضافة إلى خدمة الواي فاي مجاناً في جميع أنحاء مكان الإقامة . يحتوي الفندق على مسبح داخلي ومركز سبا وفريق الأنشطة الترفيهية ومكتب استقبال يعمل على مدار 24 ساعة . يقدم الفندق وجبة إفطار كونتيننتال أو بوفيه إفطار . يمكن للضيوف في فندق كراوان جدة الاستفادة من الساونا . يوفر مكان الإقامة أيضاً مركز للأعمال، ويمكن للضيوف قراءة الصحف واستخدام جهاز الفاكس وآلة تصوير المستندات أو استخدام ماكينة الصراف الآلي الموجودة في الموقع في فندق كراوان جدة . يبعد هذا الفندق مسافة 9 كم من مول ريد سي وفقيه أكواريوم . يعتبر مطار الملك عبد العزيز الدولي المطار الأقرب إلى فندق كراوان جدة، حيث يقع على بعد 9 كم .    ",
      en: "Carwan Hotel Jeddah is a hotel located on Mohammed Zaidan Street, Al Naeem district in Jeddah. The hotel is 2.9 km from Arab Mall and offers accommodation with a restaurant, free private parking, an outdoor swimming pool, and a fitness center. The facilities available at the property include room service and a shared lounge, as well as free Wi-Fi throughout the property. The hotel has an indoor pool, a spa center, an entertainment activities team, and a 24-hour reception desk. The hotel offers a continental breakfast or a breakfast buffet. Guests at Carwan Hotel Jeddah can benefit from the sauna. The property also offers a business center, and guests can read newspapers, use the fax machine and photocopier, or use the ATM on-site at Carwan Hotel Jeddah. This hotel is 9 km from Red Sea Mall and Fakieh Aquarium. King Abdulaziz International Airport is the nearest airport to Carwan Hotel Jeddah, located 9 km away.",
    },

    services: {
      ar: [
        "مطعم",
        "ساونا",
        "غرفة بخار",
        "ميني جيم",
        "خدمة حمل الأمتعة",
        "قنوات فضائية",
        "انترنت عالي السرعة",
        "مغسلة مركزية",

        "كوفي شوب على مدار الساعة",
        "حمام سباحة اطفال",
        "منطقة العاب اطفال",
        "خدمة نظافة الغرف",
        "خدمة غرف شهر العسل",

        "خدمة التوصيل من والى المطار",
        "خدمة طباعة المتستندات",
        "مكتب امن",
        "مكتب استقبال على مدار الساعة",
        "خزائن لحفظ الودائع الشخصية",
      ],
      en: [
        "Restaurant",
        "Sauna",
        "Steam room",
        "Mini gym",
        "Luggage service",
        "Satellite channels",
        "High-speed internet",
        "Central laundry",

        "24-hour coffee shop",
        "Children's swimming pool",
        "Children's play area",
        "Room cleaning service",
        "Honeymoon room service",

        "Airport pick-up and drop-off service",
        "Document printing service",
        "Security office",
        "24-hour reception desk",
        "Lockers for personal deposits",
      ],
    },

    email: "carawanhotel@alghanmigroup.com",
    phone: "+966 55 886 4164",
    instagram: "https://www.instagram.com/crownjeddah",
    facebook:
      "https://www.facebook.com/profile.php?id=61551250558731&utm_medium=social&utm_source=heylink.me",
    x: "https://x.com/crownjedahhotel?s=11&utm_medium=social&utm_source=heylink.me",
    address: {
      en: "3632-3542 Mohammed Zaidan, Al Naeem, Jeddah 23526, Saudi Arabia",
      ar: "3632-3542 محمد زيدان، النعيم، جدة 23526، المملكة العربية السعودية",
    },

    images: [
      "/assets/images/carwan/1.jpg",
      "/assets/images/carwan/2.jpg",
      "/assets/images/carwan/3.jpg",
      "/assets/images/carwan/4.jpg",
      "/assets/images/carwan/5.jpg",
      "/assets/images/carwan/6.jpg",
      "/assets/images/carwan/7.jpg",
      "/assets/images/carwan/8.jpg",
      "/assets/images/carwan/9.jpg",
      "/assets/images/carwan/10.jpg",
      "/assets/images/carwan/11.jpg",
      "/assets/images/carwan/12.jpg",
      "/assets/images/carwan/13.jpg",
      "/assets/images/carwan/14.jpg",
      "/assets/images/carwan/15.jpg",
      "/assets/images/carwan/16.jpg",
      "/assets/images/carwan/17.jpg",
      "/assets/images/carwan/18.jpg",
      "/assets/images/carwan/19.jpg",
    ],
    booking: "https://www.booking.com/hotel/sa/crown-al-naeem-jd.ar.html",
    map_src:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14836.635984808288!2d39.171026044580074!3d21.618727200000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3dbc8d7fd8185%3A0xb357b2f9deb3267c!2z2YHZhtiv2YIg2YPYsdmI2KfZhiDYrNiv2Kk!5e0!3m2!1sar!2sar!4v1701416083243!5m2!1sar!2sar",
  },
  {
    id: "4",
    english_name: "yan",
    name: {
      ar: "شقق يان المخدومة",
      en: "Yan Furnished Apartments ",
    },
    logo: "/assets/images/yan-logo.png",
    home_image: "assets/images/yan-hotel.png",
    home_description: {
      ar: " شقق يان المفروشة في جدة هي وحدات سكنية تقع في شارع شجاع بن وهب . تتميز هذه الشقق بتقييم 8,9 ، وتوفر إقامة ذات 3 نجوم مع موقف سيارات مجاني وصالون حلاقة . يقع هذا المكان في منطقة السلامة ويبعد 1,5 كم عن روائع المكتبات  ",
      en: "Yan Furnished Apartments in Jeddah are residential units located on Shuja Bin Wahb Street. These apartments are rated 8,9 and offer 3-star accommodation with free parking and a barber shop",
    },
    full_description: {
      ar: " شقق يان المفروشة في جدة هي وحدات سكنية تقع في شارع شجاع بن وهب . تتميز هذه الشقق بتقييم 8.9 ، وتوفر إقامة ذات 3 نجوم مع موقف سيارات مجاني وصالون حلاقة . يقع المكان في منطقة السلامة ويبعد 1.5 كم عن روائع المكتبات . الملكية تقع على بعد فقط 2.8 كم من ميدان التاريخ . توفر هذه الشقق غرفاً مزودة بمنطقة جلوس ومكتب للعمل وغلاية كهربائية . كل غرفة تتضمن حمام خاص مزود بحوض استحمام . يمكن للضيوف الوصول بسهولة إلى مطار الملك عبد العزيز الدولي الذي يقع على بعد حوالي 15 دقيقة بالسيارة .",
      en: "Yan Furnished Apartments in Jeddah are residential units located on Shuja Bin Wahb Street. These apartments are rated 8.9 and offer 3-star accommodation with free parking and a barber shop. The property is located in the Al Salamah area and is 1.5 km from the Library Masterpieces. The property is just 2.8 km from History Square. These apartments offer rooms equipped with a seating area, a work desk, and an electric kettle. Each room includes a private bathroom with a bathtub. Guests can easily access King Abdulaziz International Airport, which is about a 15-minute drive away.",
    },

    services: {
      ar: [
        "كوفي شوب على مدار الساعة",
        "خدمة نظافة الغرف",
        "خدمة حمل الامتعة",
        "قنوات فضائية",
        "انترنت عالي السرعة",
        "مرافق الكي و الغسل",
        "خدمة غرف شهر العسل",
        "مكتب امن",
        "خدمة التوصيل من والى المطار",
        "خدمة طباعة المتستندات",
        "مكتب استقبال على مدار الساعة",
        "خزائن لحفظ الودائع الشخصية",
      ],
      en: [
        "24-hour coffee shop",
        "Room cleaning service",
        "Luggage service",
        "Satellite channels",
        "High-speed internet",
        "Ironing and washing facilities",
        "Honeymoon room service",
        "Security office",
        "Airport pick-up and drop-off service",
        "Document printing service",
        "24-hour reception desk",
        "Lockers for personal deposits",
      ],
    },

    booking: "https://www.booking.com/Share-SCBtWI",
    email: "yanapartment@alghanmigroup.com",
    instagram: "",
    facebook: "",
    x: "https://x.com/Yan_apartments?s=20&utm_medium=social&utm_source=heylink.me",
    phone: "+966 55 707 0501",
    address: {
      en: "JESA2744, 2744 Shuja Bin Wahb, 7436, Al Salamah District, Jeddah 23437, Saudi Arabia ",
      ar: "JESA2744، 2744 شجاع بن وهب، 7436، حي السلامة، جدة 23437، المملكة العربية السعودية",
    },
    map_src:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3709.9647250609296!2d39.14792507527218!3d21.58729998020547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDM1JzE0LjMiTiAzOcKwMDknMDEuOCJF!5e0!3m2!1sen!2s!4v1701157609657!5m2!1sen!2s",
    images: [
      "/assets/images/yan/1.jpg",
      "/assets/images/yan/2.jpg",
      "/assets/images/yan/3.jpg",
      "/assets/images/yan/4.jpg",
      "/assets/images/yan/5.jpg",
      "/assets/images/yan/6.jpg",
      "/assets/images/yan/7.jpg",
      "/assets/images/yan/8.jpg",
      "/assets/images/yan/9.jpg",
      "/assets/images/yan/10.jpg",
      "/assets/images/yan/11.jpg",
      "/assets/images/yan/12.jpg",
      "/assets/images/yan/13.jpg",
      "/assets/images/yan/14.jpg",
      "/assets/images/yan/15.jpg",
      "/assets/images/yan/16.jpg",
      "/assets/images/yan/17.jpg",
     ],
  },
  {
    id: "5",
    english_name: "waw-jada-2",
    name: { ar: " فندق واو جدة النعيم ", en: "Jeddah Wow Hotel (Al Naeem)" },

    logo: "/assets/images/waw-jada-logo.png",
    home_image: "assets/images/waw-jada-2.jpeg",

    home_description: {
      ar: " فندق واو جدة - النعيم هو فندق يقع في شارع محمد زيدان، حي النعيم في جدة . يتميز الفندق بموقعه الحيوي على مقربة من أروع وجهات التسوق في المدينة . يوفر الفندق غرفاً مريحة ومرافق ممتازة  ",
      en: "Wow Jeddah  Hotel is a hotel located on Mohammed Zaidan Street, Al Naeem district in Jeddah. The hotel is characterized by its vibrant location close to the finest shopping destinations in the city. The hotel offers comfortable rooms and excellent facilities",
    },
    full_description: {
      ar: "  فندق واو جدة - النعيم هو فندق يقع في شارع محمد زيدان، حي النعيم في جدة . يتميز الفندق بموقعه الحيوي على مقربة من أروع وجهات التسوق في المدينة . يوفر الفندق غرفاً مريحة ومرافق ممتازة . يتميز الفندق بوحدات الإقامة المذهلة والإطلالة الاستثنائية على المدينة . توفر العديد من الوحدات غرفة معيشة ومطبخ وخدمة الواي فاي ومنطقة جلوس وميني بار وماكينة تحضير الشاي والقهوة . يمكن للضيوف الاستمتاع بالمأكولات الغنية بالنكهات التي يقدمها مطعم الفندق . يقع ريد سي مول على بعد 8 كم من الفندق . يعتبر مطار الملك عبد العزيز الدولي المطار الأقرب إلى الفندق .    ",
      en: "Wow Jeddah - Al Naeem Hotel is a hotel located on Mohammed Zaidan Street, Al Naeem district in Jeddah. The hotel is characterized by its vibrant location close to the finest shopping destinations in the city. The hotel offers comfortable rooms and excellent facilities. The hotel features stunning accommodation units and exceptional city views. Many units offer a living room, kitchen, Wi-Fi service, seating area, mini bar, and tea and coffee making facilities. Guests can enjoy the rich flavors offered by the hotel’s restaurant. Red Sea Mall is located 8 km from the hotel. King Abdulaziz International Airport is the nearest airport to the hotel.",
    },
    services: {
      ar: [
        "مطعم",
        "ساونا",
        "غرفة بخار",
        "ميني جيم",
        "خدمة حمل الأمتعة",
        "قنوات فضائية",
        "انترنت عالي السرعة",
        "مغسلة مركزية",

        "كوفي شوب على مدار الساعة",
        "حمام سباحة اطفال",
        "منطقة العاب اطفال",
        "خدمة نظافة الغرف",
        "خدمة غرف شهر العسل",

        "خدمة التوصيل من والى المطار",
        "خدمة طباعة المتستندات",
        "مكتب امن",
        "مكتب استقبال على مدار الساعة",
        "خزائن لحفظ الودائع الشخصية",
      ],
      en: [
        "Restaurant",
        "Sauna",
        "Steam room",
        "Mini gym",
        "Luggage service",
        "Satellite channels",
        "High-speed internet",
        "Central laundry",

        "24-hour coffee shop",
        "Children's swimming pool",
        "Children's play area",
        "Room cleaning service",
        "Honeymoon room service",

        "Airport pick-up and drop-off service",
        "Document printing service",
        "Security office",
        "24-hour reception desk",
        "Lockers for personal deposits",
      ],
    },
    email: "wowhotel@alghanmigroup.com",
    phone: "+966 55 735 3410",
    instagram:
      "https://www.instagram.com/wow_jeddah_hotel/?igshid=MzMyNGUyNmU2YQ%3D%3D",
    facebook: "",
    x: "https://x.com/wow_jeddah?t=_Xhf9jzB88ZqIrgG9PhGQg&s=09",
    address: {
      en: "Al Naeem, Jeddah 23526, Saudi Arabia",
      ar: "النعيم، جدة 23526، المملكة العربية السعودية ",
    },
    map_src:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2717.6275078902136!2d39.149581162951364!3d21.618150656685604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3db32240df937%3A0xf0413b1dc0d4b73d!2sWOW%20_%20JEDDAH%20HOTEL!5e0!3m2!1sen!2snl!4v1701411596721!5m2!1sen!2snl",
    images: [
      "/assets/images/waw-jada-2/1.jpg",
      "/assets/images/waw-jada-2/2.jpg",
      "/assets/images/waw-jada-2/3.jpg",
      "/assets/images/waw-jada-2/4.jpg",
      "/assets/images/waw-jada-2/5.jpg",
      "/assets/images/waw-jada-2/6.jpg",
      "/assets/images/waw-jada-2/7.jpg",
      "/assets/images/waw-jada-2/8.jpg",
      "/assets/images/waw-jada-2/9.jpg",
      "/assets/images/waw-jada-2/10.jpg",
      "/assets/images/waw-jada-2/11.jpg",
     
    ],
  },
];
